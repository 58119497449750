<template>
  <v-container fluid>
    <v-toolbar>
      <v-app-bar-nav-icon @click.stop="$router.go(-1)">
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>
        <h4>{{$store.getters.onScreen_cliente_nome}} - Monitor de dispositivos</h4>
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-spacer></v-spacer>

    <v-container class="pa-4" fluid grid-list-md="grid-list-md">
      <v-overlay v-if="initialLoad" :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <!-- Área de botões de controle -->
      <v-layout wrap="wrap" class="d-flex align-center justify-space-between">
        <!-- ADICIONAR REGISTRO -->
        <v-btn color="blue-grey" class="ma-2 white--text" @click.native="novoDevice">
          Adicionar veículo
          <v-icon right dark>mdi-plus</v-icon>
        </v-btn>

        <!-- Atualizado em HH:mm -->
        <div class="d-flex align-center">

          <v-progress-circular
            v-if="overlay && !initialLoad"
            indeterminate
            size="30"
            width="2"
            color="blue-grey"
            class="ml-2"
          ></v-progress-circular>

          <span v-if="lastUpdated" class="ml-2">Atualizado em {{ lastUpdated }}</span>
        
        </div>
      </v-layout>

      <v-layout wrap="wrap">
        <v-flex xs12="xs12" md12="md12">
          <v-data-iterator
            :items="items"
            :items-per-page.sync="itemsPerPage"
            :page="page"
            :search="search"
            :sort-by="sortBy.toLowerCase()"
            :sort-desc="sortDesc"
            hide-default-footer
          >
            <template v-slot:header>
              <v-toolbar class="mb-1" elevation="1">
                <v-text-field
                  v-model="search"
                  clearable
                  flat
                  solo-inverted
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  label="Pesquisar"
                ></v-text-field>
                <template v-if="$vuetify.breakpoint.mdAndUp">
                  <v-spacer></v-spacer>
                  <v-select
                    v-model="sortBy"
                    flat
                    solo-inverted
                    hide-details
                    :items="keys"
                    prepend-inner-icon="mdi-sort"
                    label="Ordenar por"
                  ></v-select>
                  <v-spacer></v-spacer>
                  <v-btn-toggle v-model="sortDesc" mandatory>
                    <v-btn large depressed color="blue" :value="false">
                      <v-icon>mdi-arrow-up</v-icon>
                    </v-btn>
                    <v-btn large depressed color="blue" :value="true">
                      <v-icon>mdi-arrow-down</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </template>
              </v-toolbar>
            </template>

            <template v-slot:default="props">
              <v-row>
                <v-col
                  v-for="item in props.items"
                  :key="item.cadastro.label"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <v-card
                    v-ripple
                    class="clickable"
                    style="cursor:pointer;"
                    :color="item.alerta > 0 ? ( item.alerta == 3 ? 'red': ( item.alerta == 2 ? 'warning':'yellow') ) : 'gray'"
                    @click.native="selectItem(item.id)"
                  >
                    <v-card-text>
                      <v-list-item class="grow">
                        <v-list-item-icon>
                          <v-icon v-if="item.alerta <= 0" color="gray">mdi-car</v-icon>
                          <v-icon v-else color="black">mdi-alert</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            <h4>{{ item.cadastro.label }}</h4>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>

            <template v-slot:footer>
              <v-row class="mt-2" align="center" justify="center">
                <span class="grey--text">Itens por página</span>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark text color="primary" class="ml-2" v-bind="attrs" v-on="on">
                      {{ itemsPerPage }}
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(number, index) in itemsPerPageArray"
                      :key="index"
                      @click="updateItemsPerPage(number)"
                    >
                      <v-list-item-title>{{ number }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-spacer></v-spacer>

                <span class="mr-4 grey--text">
                  Página {{ page }} de {{ numberOfPages }}
                </span>
                <v-btn fab small dark color="indigo" class="mr-1" @click="formerPage">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn fab small dark color="indigo" class="ml-1" @click="nextPage">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-iterator>
        </v-flex>
      </v-layout>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "DeviceMonitor",
  data () {
    return {
      // Controls the button loader indicator and the overlay during async calls.
      overlay: false,
      // Only used to display the full screen overlay on the first load.
      initialLoad: true,
      itemsPerPageArray: [12, 24, 36],
      itemsPerPage: 12,
      search: '',
      filter: {},
      sortDesc: true,
      page: 1,
      sortBy: 'alerta',
      keys: [
        'alerta',
        'cadastro.label',
      ],
      items: [],
      menus: [
        { text:"Editar", route:"/link1", icon: "mdi-home" }
      ],
      timer: null, // Holds the interval ID for auto reload
      lastUpdated: '' // Holds the time string in HH:mm format
    }
  },
  computed: {
    numberOfPages () {
      return Math.ceil(this.items.length / this.itemsPerPage)
    },
    filteredKeys () {
      return this.keys.filter(key => key !== 'Name')
    },
  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number
    },
    selectItem(device_id) {
      this.$store.commit('storeDeviceID', device_id)
      this.$router.push('/home')
    },
    novoDevice() {
      console.log('Chegou')
      this.$store.commit('storeDeviceID', null)
      this.$router.push({ name: "cadastroDevice" });
    },
    start_load() {
      // Set the overlay flag to true so the button shows a loader.
      this.overlay = true

      this.$http
        .get(`${process.env.VUE_APP_ENDPOINT_ROOT}/devices/${this.$store.getters.onScreen_cliente_id}/${this.$store.getters.token}`)
        .then(response => {
          if (Array.isArray(response.data.clientes_data) && response.data.clientes_data.length > 0) {
            // Sort devices by the "alerta" property in descending order.
            this.items = response.data.clientes_data.sort((a, b) => (a.alerta < b.alerta) ? 1 : -1)
          } else {
            this.items = []
          }
          // Update the lastUpdated property with current time
          this.updateLastUpdated()
          // Turn off the overlay/loader flag.
          this.overlay = false
          // After the first load, disable the full screen overlay.
          if (this.initialLoad) {
            this.initialLoad = false
          }
        })
        .catch(error => {
          console.error('Erro ao carregar dispositivos:', error)
          // Optionally, update the time even on error:
          this.updateLastUpdated()
          this.overlay = false
          if (this.initialLoad) {
            this.initialLoad = false
          }
        });
    },
    updateLastUpdated() {
      const now = new Date()
      const hours = String(now.getHours()).padStart(2, '0')
      const minutes = String(now.getMinutes()).padStart(2, '0')
      this.lastUpdated = `${hours}:${minutes}`
    },
    cancelAutoUpdate () {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    }
  },
  mounted() {
    // Initial load (this will show the full screen overlay)
    this.start_load()

    // Set up auto reload every 2 seconds (2000 milliseconds)
    this.timer = setInterval(this.start_load, 10000)
  },
  beforeDestroy() {
    this.cancelAutoUpdate()
  }
}
</script>
